
























import { Component } from "vue-property-decorator";
import { Routes } from "@/types/core";
import { mixins } from "vue-class-component";
import userRolesMixin from "@/mixins/userRoles";
import { BaseView } from "@/core/UI/Views/BaseView";
import EventsList from "@widgets/events/EventsList.vue";

@Component({
  components: { EventsList },
})
export default class Events extends mixins(BaseView, userRolesMixin) {
  protected readonly Routes: typeof Routes = Routes;

  public created(): void {
    if (!this.VIEW_EVENT) this.$router.push({ name: Routes.noRights });
  }
}
